// @flow
import React from 'react';
import { Layout } from 'src/components';
import { SITE_METADATA } from 'src/constants';
import TopText from 'src/components/Landing/TopText';
import NoPayment12Pay from 'src/components/Landing/NoPayment12Pay';
import UseInstallments12Pay from 'src/components/Landing/UseInstallments12Pay';
import VerifyIdentity from 'src/components/Landing/VerifyIdentity';
import styles from '../styles/pages/landing.module.scss';
import ConversionTop6Pay from '../components/Landing/ConversionTop6Pay';
import Point from '../components/Landing/Point';
import Step from '../components/Landing/Step';
import Can from '../components/Landing/Can';
import ConversionBottom12Pay from '../components/Landing/ConversionBottom12Pay';
import Plus12Pay from '../components/Landing/Plus12Pay';
import Footer6Pay from '../components/Landing/Footer6Pay';
import TopBanner from '../components/Landing/TopBanner';

const SEOProps = {
  title: SITE_METADATA.landing_12pay_ekyc.title,
  description: SITE_METADATA.landing_12pay_ekyc.description,
};

const TOP_TEXT =
  '本ストアでペイディを利用する場合、ペイディアプリから、本人確認が必要です。本人確認をすることで、より安心してお買い物をお楽しみいただけます。また、分割手数料無料*の３・６・12回あと払いなど、便利な機能が利用可能になります。';

const Landing6PayEKYC = () => {
  return (
    <Layout SEOProps={SEOProps} hasNoFooter hasNoHeader>
      <div className={styles.landing}>
        <TopBanner />
        <ConversionTop6Pay />
        <TopText text={TOP_TEXT} />
        <VerifyIdentity />
        <Point />
        <Step />
        <NoPayment12Pay />
        <UseInstallments12Pay />
        <Can />
        <Plus12Pay />
        <ConversionBottom12Pay />
        <Footer6Pay />
      </div>
    </Layout>
  );
};

export default Landing6PayEKYC;

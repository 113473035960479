import React from 'react';
import styles from './TopText.module.scss';

const TEXT =
  '本ストアでペイディを利用する場合、ペイディアプリから、本人確認が必要です。本人確認をすることで、より安心してお買い物をお楽しみいただけます。また、分割手数料無料*の３・６回あと払いなど、便利な機能が利用可能になります。';

export default function TopText({ text = TEXT }) {
  return (
    <section className={styles.section}>
      <h2 className={styles.text}>{text}</h2>
    </section>
  );
}
